<template>
    <div class="section">
        <page-title :title="$t('sst.pages.previousBaf.title')" class="my-3"></page-title>
        <b-card>
            <b-row>
                <b-col class="lg-12">
                    <spinner v-if="refreshData"></spinner>
                    <div v-if="!refreshData">
                        <custom-table :tableConfig="tableConfig" :fields="fields" :items="items" :page="page"></custom-table>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import Spinner from '@/components/shared/element/Spinner';
import CustomTable from '@/components/shared/table/CustomTable';
import { BafRepository } from '@/repositories';
import { TABLE } from '@/constants';

export default {
    name: 'SstBafPreviousList',
    components: {
        PageTitle,
        Spinner,
        CustomTable
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'id',
                    label: this.$t('sst.pages.previousBaf.table.columns.id'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'invoice_date',
                    label: this.$t('sst.pages.previousBaf.table.columns.invoiceDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'invoice_ref',
                    label: this.$t('sst.pages.previousBaf.table.columns.invoiceRef'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ht_amount',
                    label: this.$t('sst.pages.previousBaf.table.columns.htAmount'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'nb_interventions',
                    label: this.$t('sst.pages.previousBaf.table.columns.nbInterventions'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'actions',
                    label: this.$t('sst.pages.previousBaf.table.columns.actions'),
                    sortable: false,
                    class: 'text-center'
                }
            ],
            tableConfig: {
                sortBy: 'invoice_date',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                currentPage: 1,
                displayCount: false,
                displayTotalRows: false
            },
            page: TABLE.PAGE.SST.PREVIOUS_BAF.ID,
            refreshData: false
        };
    },
    created() {
        this.loadPreviousBaf();
    },
    methods: {
        loadPreviousBaf() {
            this.refreshData = true;
            this.items = [];
            BafRepository.previousBaf().then(response => {
                this.items = response.data.data;
                this.refreshData = false;
            });
        }
    }
};
</script>

<style scoped></style>
